import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../Layout/Layout';
import { Box, Typography, Button } from '@mui/material';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';

const PendingApproval: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigation = () => {
        navigate('/dashboard');
    };

    return (
        <Layout>
            <Box 
                mt={3} 
                textAlign="center" 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center' 
                }}
            >
                <HourglassTopTwoToneIcon 
                    sx={{ fontSize: 60, mb: 2 }} 
                    aria-label="Pending approval icon" 
                />
                <Typography 
                    variant="h4" 
                    gutterBottom
                >
                    Pending Approval
                </Typography>
                <Typography 
                    variant="body1" 
                    paragraph
                >
                    Your admin approval request is pending. We've emailed a current admin in your company for their approval. Please follow up with them to expedite your request.
                    <br /><br />
                    Once your approval is granted, you will receive an email notification to confirm your new admin status.
                    <br /><br />
                    In the meantime, you can still access all of the user features available.
                </Typography>
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleNavigation}
                >
                    User Dashboard
                </Button>
            </Box>
        </Layout>
    );
};

export default PendingApproval;
