import React from 'react';
import Layout from '../../Layout/Layout';
import { Box, Typography } from '@mui/material';

const EventManagement: React.FC = () => {
  return (
    <Layout>
      <Box mt={3} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Event Management
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Create and manage events, including setting details and tokens.
        </Typography>
        {/* Add content and functionality for event management */}
      </Box>
    </Layout>
  );
};

export default EventManagement;
