import React, { useState } from 'react';
import Layout from '../../Layout/Layout';
import { Box, Typography, Card, CardContent, Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Checkbox, IconButton, Paper, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

const mockGroupsData = [
  { id: 1, username: 'johndoe', email: 'johndoe@example.com', group: 'Test1' },
  { id: 2, username: 'janedoe', email: 'janedoe@example.com', group: 'Group 2' },
  { id: 3, username: 'jdoe', email: 'jdoe@example.com' },
  { id: 4, username: 'johndoe', email: 'johndoe@example.com', group: 'Testd1' },
  { id: 5, username: 'janedoe', email: 'janedoe@example.com', group: 'Group2' },
  { id: 6, username: 'jdoe', email: 'jdoe@example.com', group: 'da' },
  { id: 7, username: 'johndoe', email: 'johndoe@example.com', group: 'Tes1' },
  { id: 9, username: 'jdoe', email: 'jdoe@example.com', group: 'gaf' },
];

interface Group {
  id: number;
  username: string;
  email: string;
  group?: string;
}

const GroupManagementPage: React.FC = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [page, setPage] = useState<number>(1);
  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(new Set());
  const itemsPerPage = 6;

  const handleOpenDialog = (group: Group | null) => {
    setSelectedGroup(group);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedGroup(null);
    setSelectedUsers(new Set());
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  // Filter unique groups, ensuring we filter out undefined values
  const uniqueGroups = Array.from(new Set(
    mockGroupsData
      .filter(user => user.group !== undefined)
      .map(user => user.group as string) // Type assertion to ensure non-null string
  ));

  // Create a map of group names to their corresponding users
  const groupToUsersMap = uniqueGroups.reduce((map, group) => {
    if (group !== undefined) { // Check to ensure group is defined
      map[group] = mockGroupsData.filter(user => user.group === group);
    }
    return map;
  }, {} as Record<string, Group[]>);

  // Paginate unique groups
  const paginatedGroups = uniqueGroups
    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
    .map(group => ({
      group,
      users: groupToUsersMap[group] || [] // Ensure users is not undefined
    }));

  const handleUserSelect = (userId: number) => {
    setSelectedUsers(prev => {
      const updated = new Set(prev);
      if (updated.has(userId)) {
        updated.delete(userId);
      } else {
        updated.add(userId);
      }
      return updated;
    });
  };

  const handleBulkTransfer = () => {
    console.log('Bulk transfer selected users');
  };

  const handleRemoveUser = (userId: number) => {
    console.log('Remove user', userId);
  };

  const handleTransferUser = (userId: number) => {
    console.log('Transfer user', userId);
  };

  const getFilteredUsers = () => {
    if (selectedGroup) {
      return mockGroupsData.filter(user => user.group === selectedGroup.group);
    }
    return mockGroupsData.filter(user => !user.group);
  };

  return (
    <Layout>
      <Box mt={3} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Group Management
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Manage groups, including creating, editing, and deleting groups.
        </Typography>

        <Box display="flex" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => console.log('Create New Group')}
            sx={{ boxShadow: 2, '&:hover': { boxShadow: 4 } }}
          >
            Create New Group
          </Button>
        </Box>
        <Grid container spacing={2} justifyContent="center">
          {/* Grid item for Unassigned Card */}
          <Grid item xs={12} sm={4} md={3}>
            <Card sx={{ boxShadow: 2, borderRadius: 2 }}>
              <CardContent>
                <Typography variant="h6">Unassigned</Typography>
                <Button
                  variant="contained"
                  onClick={() => handleOpenDialog(null)}
                  sx={{ mt: 1 }}
                >
                  View Users
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Grid item for Group Cards */}
          <Grid item xs={12} sm={8} md={9}>
            <Grid container spacing={2} justifyContent="center">
              {paginatedGroups.map(({ group, users }) => (
                <Grid item xs={12} sm={6} md={4} key={group}>
                  <Card sx={{ height: '100%', boxShadow: 2, borderRadius: 2 }}>
                    <CardContent>
                      <Typography variant="h6">{group}</Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleOpenDialog({ id: -1, username: '', email: '', group })}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        View Users
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => console.log('Edit Group', group)}
                      >
                        Edit Group
                      </Button>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>

        {/* Pagination */}
        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={Math.ceil(uniqueGroups.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{ '& .MuiPaginationItem-root': { borderRadius: 2 } }}
          />
        </Box>

        {/* User Information Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
          <DialogTitle>{selectedGroup ? selectedGroup.group : 'Unassigned Users'}</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers.size === getFilteredUsers().length}
                        onChange={() => {
                          if (selectedUsers.size === getFilteredUsers().length) {
                            setSelectedUsers(new Set());
                          } else {
                            setSelectedUsers(new Set(getFilteredUsers().map(user => user.id)));
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getFilteredUsers().map((user) => (
                    <TableRow key={user.id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUsers.has(user.id)}
                          onChange={() => handleUserSelect(user.id)}
                        />
                      </TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleRemoveUser(user.id)}
                          sx={{ color: 'error.main', '&:hover': { backgroundColor: 'action.hover' } }}
                        >
                          <DeleteIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleTransferUser(user.id)}
                          sx={{ color: 'info.main', '&:hover': { backgroundColor: 'action.hover' } }}
                        >
                          <TransferWithinAStationIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleBulkTransfer}
              disabled={selectedUsers.size === 0}
            >
              Bulk Transfer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default GroupManagementPage;
