import React, { useState, useEffect, useMemo, createContext, useContext } from "react";
import ReactDOM from "react-dom/client";
import { useMediaQuery, createTheme, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import SignUp from "./Pages/SignUp";
import { CustomTheme } from "./types/types";
import "./CSS/index.css";
import Admin from "./Pages/Admin";
import UserManagement from "./Pages/Admin/UserManagement";
import EventManagement from "./Pages/Admin/EventManagement";
import GroupManagement from "./Pages/Admin/GroupManagement";
import PendingApproval from "./Pages/Admin/PendingApproval";


export interface AuthResponse {
  success: string,
  isAdmin: string;
}
export interface CookieResponse {
  Exists: boolean,
}

interface AuthContextProps {
  isAuthenticated: boolean;
  isAdmin: string;
  isAuthLoading: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAdmin: React.Dispatch<React.SetStateAction<string>>;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState("User");
  const [isAuthLoading, setIsAuthLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const cookieResponse = await fetch('/api/checkCookie', {
          method: 'GET',
          credentials: 'include',
          headers: { "Content-Type": "application/json" }
        });
        const cookieData: CookieResponse = await cookieResponse.json();
  
        if (cookieData.Exists) {
          const authResponse = await fetch('/api/auth/verify', {
            method: 'GET',
            credentials: 'include',
          });
          const authData: AuthResponse = await authResponse.json();
  
          if (authResponse.ok) {
            setIsAuthenticated(true);
            setIsAdmin(authData.isAdmin);
          } else {
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
          setIsAdmin("User");
        }
      } catch (error) {
        setIsAuthenticated(false);
      } finally {
        setIsAuthLoading(false);
      }
    };
  
    verifyToken();
  }, []);
  

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, isAuthLoading, setIsAuthenticated, setIsAdmin}}>
      {children}
    </AuthContext.Provider>
  );
};


interface ProtectedRouteProps {
  element: React.ReactNode;
  admin: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ admin, element }) => {
  const { isAuthenticated, isAuthLoading, isAdmin } = useAuth();
  if (isAuthLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (admin && isAdmin==="User") {
    return <Navigate to="/Dashboard" replace />;
  }
  if (admin && isAdmin==="Pending") {
    return <PendingApproval />;
  }

  return <>{element}</>;
};

// Routes Configuration
const Routes: React.FC = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "Dashboard",
      element: <ProtectedRoute admin={false} element={<Home/>}/>
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <SignUp />,
    },
    {
      path: "/admin",
      element: <ProtectedRoute admin={true} element={<Admin />} />,
    },
    {
      path: "/admin/userManagement",
      element: <ProtectedRoute admin={true} element={<UserManagement />}/>
    },
    {
      path: "/admin/eventManagement",
      element: <ProtectedRoute admin={true} element={<EventManagement />}/>
    },
    {
      path: "/admin/groupManagement",
      element: <ProtectedRoute admin={true} element={<GroupManagement />}/>
    },
  ]);

  return <RouterProvider router={router} />;
};

interface ThemeContextProps {
  toggleColorMode: () => void;
}

export const themeToggle = createContext<ThemeContextProps>({
  toggleColorMode: () => {},
});

// Theme Selection Hook
const useThemeSelection = () => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = useState<"light" | "dark">(
    prefersDarkMode ? "dark" : "light"
  );

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === 'light' && {
            background: {
              default: '#D3D3D3',
              paper: '#e0e0e0', 
            },
          }),
        },
      }) as CustomTheme,
    [mode]
  );

  return { colorMode, theme };
};

const App: React.FC = () => {
  const { theme, colorMode } = useThemeSelection();

  return (
    <themeToggle.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </themeToggle.Provider>
  );
};

// Rendering
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export { AuthProvider, useAuth };
